export enum FetchAreaType {
  Country = 'country',
  Location = 'location',
  Town = 'town',
}

export enum UserRole {
  USER = 'USER',
  MOD = 'MOD',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  TRAVELER_SERVICE = 'TRAVELER_SERVICE',
  TRAVELER_ADMIN = 'TRAVELER_ADMIN',
  TRAVELER_SALE = 'TRAVELER_SALE',
  TRAVELER_AGENT = 'TRAVELER_AGENT',
  TRAVELER_COLLABORATOR = 'TRAVELER_COLLABORATOR',
  TRAVELER_ACCOUNTING = 'TRAVELER_ACCOUNTING',
  LODGING_SERVICE = 'LODGING_SERVICE',
}

export enum AreaType {
  Country = 'country',
  Locality = 'locality',
  Town = 'town',
}
