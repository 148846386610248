import { CurrentUserDto } from './common/data.d/user.data.d';

export default function access(initialState: any) {
  const currentUser = CurrentUserDto.fromUser(initialState.currentUser);
  return {
    isMod: currentUser.isModAndAbove,
    isAdmin: currentUser.isAdminAndAbove,
    isSuperAdmin: currentUser.isSuperAdmin,
    isLodgingService: currentUser.isLodgingService,
    isTravelerService: currentUser.isTravelerService,
    isNotAgentAndCollaborator: currentUser.isNotAgentAndCollaborator,
    // Check by role
    // tour
    isTourCopy: currentUser.isTourCopy,
    isTourIndex: currentUser.isTourIndex,
    isTourCreate: currentUser.isTourCreate,
    isTourUpdate: currentUser.isTourUpdate,
    isTourDelete: currentUser.isTourDelete,
    isTourDetail: currentUser.isTourDetail,
    isTourPrint: currentUser.isTourDetail,
    // order
    isOrderIndex: currentUser.isOrderIndex,
    isOrderCreate: currentUser.isOrderCreate,
    isOrderUpdate: currentUser.isOrderUpdate,
    isOrderDelete: currentUser.isOrderDelete,
    isOrderDetail: currentUser.isOrderDetail,
    isOrderReceipt: currentUser.isOrderReceipt,
    isOrderPrint: currentUser.isOrderPrint,
    // visa
    isVisaIndex: currentUser.isVisaIndex,
    isVisaCreate: currentUser.isVisaCreate,
    isVisaUpdate: currentUser.isVisaUpdate,
    isVisaDelete: currentUser.isVisaDelete,
    isVisaDetail: currentUser.isVisaDetail,
    isVisaReceipt: currentUser.isVisaReceipt,
    isVisaPrint: currentUser.isVisaPrint,
    // role
    isRoleIndex: currentUser.isRoleIndex,
    isRoleCreate: currentUser.isRoleCreate,
    isRoleUpdate: currentUser.isRoleUpdate,
    isRoleDelete: currentUser.isRoleDelete,
    // partner
    partnerLogo: currentUser.partnerLogo,
    // customer
    isCustomerIndex: currentUser.isCustomerIndex,
    isCustomerCreate: currentUser.isCustomerCreate,
    isCustomerUpdate: currentUser.isCustomerUpdate,
    isCustomerDelete: currentUser.isCustomerDelete,
    // cost
    isCostIndex: currentUser.isCostIndex,
    isCostCreate: currentUser.isCostCreate,
    isCostUpdate: currentUser.isCostUpdate,
    isCostDelete: currentUser.isCostDelete,
    isCostDetail: currentUser.isCostDetail,
    isCostPayment: currentUser.isCostPayment,
    // cost_category
    isCostCategory: currentUser.isCostDelete,
    // supplier
    isSupplierIndex: currentUser.isSupplierIndex,
    isSupplierCreate: currentUser.isSupplierCreate,
    isSupplierUpdate: currentUser.isSupplierUpdate,
    isSupplierDelete: currentUser.isSupplierDelete,
    // agent
    isAgentIndex: currentUser.isAgentIndex,
    isAgentCreate: currentUser.isAgentCreate,
    isAgentUpdate: currentUser.isAgentUpdate,
    isAgentDelete: currentUser.isAgentDelete,
    // collaborator
    isCtvIndex: currentUser.isCtvIndex,
    isCtvCreate: currentUser.isCtvCreate,
    isCtvUpdate: currentUser.isCtvUpdate,
    isCtvDelete: currentUser.isCtvDelete,
    // staff
    isStaffIndex: currentUser.isStaffIndex,
    isStaffCreate: currentUser.isStaffCreate,
    isStaffUpdate: currentUser.isStaffUpdate,
    isStaffDelete: currentUser.isStaffDelete,
    // activity_history
    isActivityIndex: currentUser.isActivityIndex,
  };
}
