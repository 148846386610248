import request from '@/utils/request';
import { FetchHotelServiceResp, HotelService } from '@/pages/HotelService/hotel-service.data';

export async function createHotelService(params: any) {
  return request.post(`/cms/hotel-services`, {
    data: params,
  });
}

export async function fetchHotelServices(params?: any): Promise<FetchHotelServiceResp> {
  return request.get('/cms/hotel-services', {
    params,
  });
}

export async function getHotelServiceDetail(id: string, isLive?: boolean): Promise<HotelService> {
  return request.get(`${isLive ? '' : '/cms'}/hotel-services/${id}`);
}

export async function updateHotelService(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/hotel-services/${id}`, {
    data: params,
  });
}

export async function deleteHotelService(id: string): Promise<any> {
  return request.delete(`/cms/hotel-services/${id}`);
}
