import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import {
  fetch,
  getReceiptDetail,
  addReceipt,
  updateReceipt,
  approveReceipt,
  cancelReceipt,
} from './services';
import type { FetchResp, ReceiptState, Receipt } from './receipt.data';
import type { ConnectState } from '@/models/connect';
import { notification } from 'antd';

export type ReceiptModelType = {
  namespace: string;
  state: ReceiptState;
  effects: {
    fetch: Effect;
    getDetail: Effect;
    addReceipt: Effect;
    updateReceipt: Effect;
    approveReceipt: Effect;
    cancelReceipt: Effect;
  };
  reducers: {
    fetchSuccess: Reducer<ReceiptState>;
    getDetailSuccess: Reducer<ReceiptState>;
  };
};

const Model: ReceiptModelType = {
  namespace: 'receipt',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
    unread: 0,
    receipt: null,
  },

  effects: {
    *fetch({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.receipt.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchResp = yield call(fetch, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetail({ payload: { receiptId } }, { call, put }) {
      const response: Receipt = yield call(getReceiptDetail, receiptId);
      yield put({
        type: 'getDetailSuccess',
        payload: { receipt: response },
      });
    },
    *addReceipt({ payload }, { call, put }) {
      const response: Receipt = yield call(addReceipt, payload);
      yield put({
        type: 'addReceiptSuccess',
        payload: { receipt: response },
      });
    },
    *updateReceipt({ payload: { id, data } }, { call, put }) {
      const response: Receipt = yield call(updateReceipt, id, data);
      yield put({
        type: 'getDetailSuccess',
        payload: { receipt: response },
      });
      notification.success({
        description: 'The receipt has been saved.',
        message: '',
      });
    },
    *approveReceipt({ payload: { id } }, { call, put }) {
      yield call(approveReceipt, id);
      yield put({
        type: 'fetch',
        payload: {},
      });
      notification.success({
        description: 'The receipt has been approved.',
        message: '',
      });
    },
    *cancelReceipt({ payload: { id } }, { call, put }) {
      yield call(cancelReceipt, id);
      yield put({
        type: 'fetch',
        payload: {},
      });
      notification.success({
        description: 'The receipt has been cancelled.',
        message: '',
      });
    },
  },
  reducers: {
    fetchSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
