import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { deleteMenu, fetchMenus, updateMenu } from './services';
import { FetchMenusResp, MenuStateType } from '@/pages/Menu/menu.data';

export type MenuModelType = {
  namespace: string;
  state: MenuStateType;
  effects: {
    fetchMenus: Effect;
    updateMenu: Effect;
    deleteMenu: Effect;
  };
  reducers: {
    fetchMenusSuccess: Reducer<MenuStateType>;
  };
};

const Model: MenuModelType = {
  namespace: 'menu',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchMenus({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.role.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchMenusResp = yield call(fetchMenus, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchMenusSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateMenu({ payload: { id, data } }, { call }) {
      yield call(updateMenu, id, data);
    },
    *deleteMenu({ payload }, { call, put }) {
      // @ts-ignore
      // @typescript-eslint/no-shadow
      const response = yield call(deleteMenu, payload);

      if (response?.success) {
        message.success('The Menu has been deleted.').then(() => {});
        yield put({
          type: 'fetchMenus',
        });
      }
    },
  },
  reducers: {
    fetchMenusSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
