import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { Cost, CostStateType, FetchCostResp } from './cost.data';
import { deleteCost, fetchCosts, updateCost, getDetailCost } from './services';

export type CostModelType = {
  namespace: string;
  state: CostStateType;
  effects: {
    fetchCosts: Effect;
    updateCost: Effect;
    deleteCost: Effect;
    getDetailCost: Effect;
  };
  reducers: {
    fetchCostsSuccess: Reducer<CostStateType>;
    getDetailCostSuccess: Reducer<CostStateType>;
  };
};

const Model: CostModelType = {
  namespace: 'cost',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchCosts({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchCostResp = yield call(fetchCosts, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchCostsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          statistics: response.statistics,
          query,
        },
      });
    },
    *getDetailCost({ payload: { costId } }, { call, put }) {
      const response: Cost = yield call(getDetailCost, costId);
      yield put({
        type: 'getDetailCostSuccess',
        payload: { cost: response },
      });
    },
    *updateCost({ payload: { _id, data } }, { call, put }) {
      yield call(updateCost, _id, data);

      yield put({
        type: 'fetchCosts',
      });
    },
    *deleteCost({ payload }, { call, put }) {
      const response = yield call(deleteCost, payload);

      if (response?.success) {
        message.success('The cost has been deleted.');
        yield put({
          type: 'fetchCosts',
        });
      }
    },
  },
  reducers: {
    fetchCostsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailCostSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
