import request, { exportRequest } from '@/utils/request';
import type { FetchVisaResp, VisaDataType } from '@/pages/Visa/visa.data';

export async function createVisa(params: any) {
  return request.post(`/cms/visas`, {
    data: params,
  });
}

export async function fetchVisas(params?: any): Promise<FetchVisaResp> {
  return request.get('/cms/visas', {
    params,
  });
}

export async function updateVisa(id: string, params: any) {
  return request.patch(`/cms/visas/${id}`, {
    data: params,
  });
}

export async function getVisaDetail(id: string): Promise<VisaDataType> {
  return request.get(`/cms/visas/${id}`);
}

export async function deleteVisa(id: string) {
  return request.delete(`/cms/visas/${id}`);
}

export async function exportPdf(id: string) {
  return exportRequest.get(`/visas/export/${id}`);
}
