import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { FetchComboOrderResp, ComboOrder, ComboOrderStateType } from './combo-order.data';
import {
  deleteComboOrder,
  fetchComboOrders,
  getDetailComboOrder,
  updateComboOrder,
} from './services';

export type ComboOrderModelType = {
  namespace: string;
  state: ComboOrderStateType;
  effects: {
    fetchComboOrders: Effect;
    updateComboOrder: Effect;
    deleteComboOrder: Effect;
    getDetailComboOrder: Effect;
  };
  reducers: {
    fetchComboOrdersSuccess: Reducer<ComboOrderModelType>;
    getDetailComboOrderSuccess: Reducer<ComboOrderStateType>;
  };
};

const Model: ComboOrderModelType = {
  namespace: 'comboOrder',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchComboOrders({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.place.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchComboOrderResp = yield call(fetchComboOrders, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchComboOrdersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetailComboOrder({ payload: { id } }, { call, put }) {
      const response: ComboOrder = yield call(getDetailComboOrder, id);
      yield put({
        type: 'getDetailComboOrderSuccess',
        payload: { order: response },
      });
    },
    *updateComboOrder({ payload: { orderId, data } }, { call }) {
      yield call(updateComboOrder, orderId, data);
    },
    *deleteComboOrder({ payload: { id } }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteComboOrder, id);

      if (response?.success) {
        message.success('The combo order has been deleted.');
        yield put({
          type: 'fetchComboOrders',
        });
      }
    },
  },
  reducers: {
    fetchComboOrdersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailComboOrderSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
