import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import {
  deleteHotelService,
  fetchHotelServices,
  getHotelServiceDetail,
  updateHotelService,
} from './services';
import {
  FetchHotelServiceResp,
  HotelService,
  HotelServiceStateType,
} from '@/pages/HotelService/hotel-service.data';

export type HotelServiceModelType = {
  namespace: string;
  state: HotelServiceStateType;
  effects: {
    fetchHotelServices: Effect;
    updateHotelService: Effect;
    deleteHotelService: Effect;
    getHotelServiceDetail: Effect;
  };
  reducers: {
    fetchHotelServicesSuccess: Reducer<HotelServiceStateType>;
    getHotelServiceDetailSuccess: Reducer<HotelServiceStateType>;
  };
};

const Model: HotelServiceModelType = {
  namespace: 'hotelService',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchHotelServices({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchHotelServiceResp = yield call(
        fetchHotelServices,
        _.omitBy(query, _.isNil),
      );

      yield put({
        type: 'fetchHotelServicesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateHotelService({ payload: { id, data } }, { call }) {
      yield call(updateHotelService, id, data);
    },
    *getHotelServiceDetail({ payload: { id, isLive } }, { call, put }) {
      const response: HotelService = yield call(getHotelServiceDetail, id, isLive);
      yield put({
        type: 'getHotelServiceDetailSuccess',
        payload: { hotelService: response },
      });
    },
    *deleteHotelService({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteHotelService, payload);

      if (response?.success) {
        message.success('The hotel service has been deleted.');
        yield put({
          type: 'fetchHotelServices',
        });
      }
    },
  },
  reducers: {
    fetchHotelServicesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getHotelServiceDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
