import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import {
  fetch,
  countUnread,
  getPartnerDetail,
  addPartner,
  updatePartner,
  deletePartner,
} from './services';
import type { FetchResp, PartnerState, Partner } from './partner.data';
import type { ConnectState } from '@/models/connect';
import { notification } from 'antd';

export type PartnerModelType = {
  namespace: string;
  state: PartnerState;
  effects: {
    fetch: Effect;
    getDetail: Effect;
    countUnread: Effect;
    addPartner: Effect;
    updatePartner: Effect;
    deletePartner: Effect;
  };
  reducers: {
    fetchSuccess: Reducer<PartnerState>;
    getDetailSuccess: Reducer<PartnerState>;
    addPartnerSuccess: Reducer<PartnerState>;
  };
};

const Model: PartnerModelType = {
  namespace: 'partner',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
    unread: 0,
    partner: null,
  },

  effects: {
    *fetch({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.partner.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchResp = yield call(fetch, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetail({ payload: { partnerId } }, { call, put }) {
      const response: Partner = yield call(getPartnerDetail, partnerId);
      yield put({
        type: 'getDetailSuccess',
        payload: { partner: response },
      });
    },
    *addPartner({ payload }, { call, put }) {
      const response: Partner = yield call(addPartner, payload);
      yield put({
        type: 'addPartnerSuccess',
        payload: { partner: response },
      });
    },
    *updatePartner({ payload: { id, data } }, { call, put }) {
      const response: Partner = yield call(updatePartner, id, data);
      yield put({
        type: 'getDetailSuccess',
        payload: { partner: response },
      });
      notification.success({
        description: 'The partner has been saved.',
        message: '',
      });
    },
    *deletePartner({ payload: { id } }, { call, put }) {
      yield call(deletePartner, id);
      yield put({
        type: 'fetch',
        payload: {},
      });
      notification.success({
        description: 'The partner has been deleted.',
        message: '',
      });
    },

    // eslint-disable-next-line no-empty-pattern
    *countUnread({}, { call, put }) {
      const response: FetchResp = yield call(countUnread);

      yield put({
        type: 'fetchSuccess',
        payload: {
          unread: response,
        },
      });
    },
  },
  reducers: {
    fetchSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    addPartnerSuccess(state, { payload }) {
      return {
        ...state,
        list: payload.partner ? [payload.partner, ...(state?.list || [])] : state?.list,
      };
    },
  },
};

export default Model;
