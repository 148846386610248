import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { fetchTourSells, updateTourSell, deleteTourSell } from './services';
import type { FetchTourSellResp, TourSellStateType } from './tour-sell.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';

export type TourSellModelType = {
  namespace: string;
  state: TourSellStateType;
  effects: {
    fetchTourSells: Effect;
    updateTourSell: Effect;
    deleteTourSell: Effect;
  };
  reducers: {
    fetchTourSellsSuccess: Reducer<TourSellStateType>;
  };
};

const Model: TourSellModelType = {
  namespace: 'tourSell',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchTourSells({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchTourSellResp = yield call(fetchTourSells, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchTourSellsSuccess',
        payload: {
          tours: response.tours,
          tourSells: response.tourSells,
          tourOrders: response.tourOrders,
          total: response.total,
          query,
        },
      });
    },
    *updateTourSell({ payload: { tourSellId, data } }, { call }) {
      yield call(updateTourSell, tourSellId, data);
    },
    *deleteTourSell({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteTourSell, payload);

      if (response?.success) {
        message.success('The partner tour has been deleted.');
        yield put({
          type: 'fetchTours',
        });
      }
    },
  },
  reducers: {
    fetchTourSellsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
