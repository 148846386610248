import request from '@/utils/request';
import type { FetchTourSellResp } from '@/pages/TourSell/tour-sell.data';

export async function createTourSell(params: any) {
  return request.post(`/cms/tour-sells`, {
    data: params,
  });
}

export async function fetchTourSells(params?: any): Promise<FetchTourSellResp> {
  return request.get('/cms/tour-sells', {
    params,
  });
}

export async function fetchTourSellsByTour(id: string, params?: any): Promise<FetchTourSellResp> {
  return request.get(`/cms/tour-sells/find-by-tour/${id}`, {
    params,
  });
}

export async function updateTourSell(id: string, params: any) {
  return request.patch(`/cms/tour-sells/${id}`, {
    data: params,
  });
}

export async function updateOneTourSell(id: string, params: any) {
  return request.patch(`/cms/tour-sells/${id}/update-one`, {
    data: params,
  });
}

export async function deleteTourSell(id: string) {
  return request.delete(`/cms/partner-tours/${id}`);
}
