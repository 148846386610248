import request from '@/utils/request';
import type { FetchCostCategoryResp } from '@/pages/CostCategory/cost-category.data';
import { VisaService } from '@/pages/VisaService/visa-service.data';

export async function createVisaService(params: any) {
  return request.post(`/cms/visa-services`, {
    data: params,
  });
}

export async function fetchVisaServices(params?: any): Promise<FetchCostCategoryResp> {
  return request.get('/cms/visa-services', {
    params,
  });
}

export async function getVisaServiceDetail(id: string, isLive: boolean): Promise<VisaService> {
  return request.get(`${isLive ? '' : '/cms'}/visa-services/${id}`);
}

export async function updateVisaService(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/visa-services/${id}`, {
    data: params,
  });
}

export async function deleteVisaService(id: string): Promise<any> {
  return request.delete(`/cms/visa-services/${id}`);
}
