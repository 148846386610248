import request, { exportRequest } from '@/utils/request';
import type { FetchOrderResp, Order } from '@/pages/Order/order.data';

export async function createOrder(params: any): Promise<Order> {
  return request.post(`/cms/tour-orders`, {
    data: params,
  });
}

export async function fetchOrders(params?: any): Promise<FetchOrderResp> {
  return request.get('/cms/tour-orders', {
    params,
  });
}

export async function updateOrder(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/tour-orders/${id}`, {
    data: params,
  });
}

export async function getDetailOrder(id: string): Promise<Order> {
  return request.get(`/cms/tour-orders/${id}`);
}

export async function deleteOrder(id: string): Promise<any> {
  return request.delete(`/cms/tour-orders/${id}`);
}

export async function exportPdf(id: string) {
  return exportRequest.get(`/orders/export/${id}`);
}
