import request from '@/utils/request';
import type { FetchRoleResp, RoleDataType } from './role.data';

const ROLE_API = 'roles';

export async function fetchRoles(): Promise<FetchRoleResp> {
  return request.get(`/${ROLE_API}`);
}

export async function createRole(params: any): Promise<RoleDataType> {
  return request.post(`/${ROLE_API}`, {
    data: params,
  });
}

export async function updateRole(id: string, params: any) {
  return request.patch(`/${ROLE_API}/${id}`, {
    data: params,
  });
}

export async function deleteRole(id: string) {
  return request.delete(`/${ROLE_API}/${id}`);
}
