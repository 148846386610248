import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { deleteRole, fetchRoles, updateRole } from './services';
import { FetchRoleResp, RoleStateType } from '@/pages/Role/role.data';

export type RoleModelType = {
  namespace: string;
  state: RoleStateType;
  effects: {
    fetchRoles: Effect;
    updateRole: Effect;
    deleteRole: Effect;
  };
  reducers: {
    fetchRolesSuccess: Reducer<RoleStateType>;
  };
};

const Model: RoleModelType = {
  namespace: 'role',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchRoles({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.role.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchRoleResp = yield call(fetchRoles, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchRolesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateRole({ payload: { costCategoryId, data } }, { call }) {
      yield call(updateRole, costCategoryId, data);
    },
    *deleteRole({ payload }, { call, put }) {
      // @ts-ignore
      // @typescript-eslint/no-shadow
      const response = yield call(deleteRole, payload);

      if (response?.success) {
        message.success('The cost category has been deleted.').then(() => {});
        yield put({
          type: 'fetchRoles',
        });
      }
    },
  },
  reducers: {
    fetchRolesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
