import request from '@/utils/request';
import type { PlaceDataType } from '@/pages/Place/place.data';
import type { BlogDataType, FetchBlogResp } from '@/pages/Blog/blog.data';
import type { PartnerTourDataType } from '@/pages/Tour/tour.data';
import type { TripDataType } from '@/pages/Trip/trip.data';

export async function createBlog(params: any) {
  return request.post(`/cms/blogs`, {
    data: params,
  });
}

export async function fetchBlogs(params?: any): Promise<FetchBlogResp> {
  return request.get('/cms/blogs', {
    params,
  });
}

export async function getBlogDetail(id: string): Promise<{
  blog: BlogDataType;
  places: PlaceDataType;
  tours: PartnerTourDataType;
  trips: TripDataType;
}> {
  return request.get(`/cms/blogs/${id}`);
}

export async function updateBlog(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/blogs/${id}`, {
    data: params,
  });
}

export async function deleteBlog(id: string): Promise<any> {
  return request.delete(`/cms/blogs/${id}`);
}

export async function fcmSubscribe(params?: any): Promise<any> {
  return request.post(`/fcm-notifications/subscribe`, {
    data: params,
  });
}
