import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { BlogDataType, BlogStateType, FetchBlogResp } from './blog.data';
import { deleteBlog, updateBlog, fetchBlogs, getBlogDetail } from './services';

export type BlogModelType = {
  namespace: string;
  state: BlogStateType;
  effects: {
    fetchBlogs: Effect;
    updateBlog: Effect;
    deleteBlog: Effect;
    getBlogDetail: Effect;
  };
  reducers: {
    fetchBlogsSuccess: Reducer<BlogModelType>;
    getBlogDetailSuccess: Reducer<BlogModelType>;
  };
};

const Model: BlogModelType = {
  namespace: 'blog',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchBlogs({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.blog.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchBlogResp = yield call(fetchBlogs, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchBlogsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getBlogDetail({ payload: { id } }, { call, put }) {
      const response: BlogDataType = yield call(getBlogDetail, id);
      yield put({
        type: 'getBlogDetailSuccess',
        payload: { Blog: response },
      });
    },
    *updateBlog({ payload: { id, data } }, { call, put }) {
      const response = yield call(updateBlog, id, data);

      yield put({
        type: 'fetchBlogs',
      });
    },
    *deleteBlog({ payload }, { call, put }) {
      const response = yield call(deleteBlog, payload);

      if (response?.success) {
        message.success('The blog has been deleted.');
        yield put({
          type: 'fetchBlogs',
        });
      }
    },
  },
  reducers: {
    fetchBlogsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getBlogDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
