import request from '@/utils/request';

import type { FetchAreaResp, AreaDataType } from './area.data';

export async function fetchAreas(params?: any): Promise<FetchAreaResp> {
  return request.get('/cms/areas', {
    params,
  });
}

export async function getAreaDetail(id: string): Promise<AreaDataType> {
  return request.get(`/cms/areas/${id}`);
}

export async function updateArea(id: string, params: any) {
  return request.patch(`/cms/areas/${id}`, {
    data: params,
  });
}

export async function deleteArea(id: string) {
  return request.delete(`/cms/areas/${id}`);
}

export async function createArea(params: any) {
  return request.post(`/cms/areas`, {
    data: params,
  });
}
