import request from '@/utils/request';
import type { Cost, FetchCostResp } from '@/pages/Cost/cost.data';

export async function createCost(params: any) {
  return request.post(`/cms/costs`, {
    data: params,
  });
}

export async function fetchCosts(params?: any): Promise<FetchCostResp> {
  return request.get('/cms/costs', {
    params,
  });
}

export async function updateCost(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/costs/${id}`, {
    data: params,
  });
}

export async function getDetailCost(id: string): Promise<Cost> {
  return request.get(`/cms/costs/${id}`);
}

export async function deleteCost(id: string): Promise<any> {
  return request.delete(`/cms/costs/${id}`);
}
