import request from '@/utils/request';

import type { Booking, FetchResp, FetchRespType, Quotation, Receipt } from './booking.data';

const BOOKING_API = 'reservations';
const QUOTATION_API = 'quotations';
const RECEIPT_API = 'receipts';

export async function fetch(params?: any): Promise<FetchResp> {
  return request.get(`/${BOOKING_API}`, {
    params,
  });
}

export async function getBookingDetail(id: string): Promise<Booking> {
  return request.get(`/${BOOKING_API}/${id}`);
}

export async function createBooking(params: any) {
  return request.post(`/${BOOKING_API}`, {
    data: params,
  });
}

export async function updateBooking(id: string, params: any) {
  return request.patch(`/${BOOKING_API}/${id}`, {
    data: params,
  });
}

export async function sendInvoice(id: string, params?: any) {
  return request.post(`/${BOOKING_API}/${id}/send-invoice`, {
    data: params,
  });
}

export async function cancelBooking(id: string, params?: any) {
  return request.post(`/${BOOKING_API}/${id}/cancel`, {
    data: params,
  });
}

export async function completeBooking(id: string, params?: any) {
  return request.post(`/${BOOKING_API}/${id}/complete`, {
    data: params,
  });
}

export async function verifyBooking(id: string, params?: any) {
  return request.post(`/${BOOKING_API}/${id}/verify`, {
    data: params,
  });
}

export async function countUnread(params?: any): Promise<FetchResp> {
  return request.get(`/${BOOKING_API}/countUnread`, {
    params,
  });
}

export async function addQuotation(data: any) {
  return request.post(`/${QUOTATION_API}`, {
    data,
  });
}

export async function addReceipt(data: any) {
  return request.post(`/${RECEIPT_API}`, {
    data,
  });
}

export async function cancelReceipt(id: string): Promise<Receipt> {
  return request.get(`/${RECEIPT_API}/${id}/cancel`);
}

export async function approveReceipt(id: string): Promise<Receipt> {
  return request.get(`/${RECEIPT_API}/${id}/cancel`);
}

export async function getReceiptsByBooking(bookingId: string): Promise<Receipt[]> {
  return request.get(`/${RECEIPT_API}/by-booking`, {
    params: { bookingId },
  });
}

export async function updateQuotation(id: string, data: any) {
  return request.patch(`/${QUOTATION_API}/${id}`, {
    data,
  });
}

export async function cancelQuotation(id: string) {
  return request.get(`/${QUOTATION_API}/${id}/cancel`);
}

export async function fetchQuotations(params?: any): Promise<FetchRespType<Quotation>[]> {
  return request.get(`/${QUOTATION_API}/by-booking`, {
    params,
  });
}
