import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { deletePlace, fetchPlaces, updatePlace } from './services';
import type { FetchPlaceResp, PlaceStateType } from './place.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';

export type PlaceModelType = {
  namespace: string;
  state: PlaceStateType;
  effects: {
    fetchPlaces: Effect;
    updatePlace: Effect;
    deletePlace: Effect;
  };
  reducers: {
    fetchPlacesSuccess: Reducer<PlaceStateType>;
  };
};

const Model: PlaceModelType = {
  namespace: 'place',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchPlaces({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.place.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchPlaceResp = yield call(fetchPlaces, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchPlacesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updatePlace({ payload: { placeId, data } }, { call }) {
      yield call(updatePlace, placeId, data);
    },
    *deletePlace({ payload }, { call, put }) {
      const response = yield call(deletePlace, payload);

      if (response?.success) {
        message.success('The place has been deleted.');
        yield put({
          type: 'fetchPlaces',
        });
      }
    },
  },
  reducers: {
    fetchPlacesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
