import type { Reducer, Effect } from 'umi';
import _ from 'lodash';
import { message } from 'antd';

import type { ConnectState } from '@/models/connect';
import { createArea, deleteArea, fetchAreas, updateArea } from './services';
import type { FetchAreaResp, AreaStateType } from './area.data';

export type AreaModelType = {
  namespace: string;
  state: AreaStateType;
  effects: {
    createArea: Effect;
    fetchAreas: Effect;
    updateArea: Effect;
    deleteArea: Effect;
  };
  reducers: {
    fetchAreasSuccess: Reducer<AreaStateType>;
  };
};

const Model: AreaModelType = {
  namespace: 'area',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchAreas({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.area.query);

      const query = !_.get(updatedParams, 'type')
        ? _.omit({ ...currentQuery, ...updatedParams }, ['type'])
        : { ...currentQuery, ...updatedParams };

      const response: FetchAreaResp = yield call(fetchAreas, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchAreasSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *createArea({ payload: { data } }, { call, put }) {
      const response = yield call(createArea, data);

      if (response?.id) {
        message.success('The area has been created.');
        yield put({
          type: 'fetchAreas',
        });
      }
    },
    *updateArea({ payload: { areaId, data } }, { call, put }) {
      const response = yield call(updateArea, areaId, data);

      if (response?.id) {
        message.success('The area has been updated.');
        yield put({
          type: 'fetchAreas',
        });
      }
    },
    *deleteArea({ payload }, { call, put }) {
      const response = yield call(deleteArea, payload);

      if (response?.deletedId) {
        message.success('The area has been deleted.');
        yield put({
          type: 'fetchAreas',
        });
      }
    },
  },
  reducers: {
    fetchAreasSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
