import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { Customer, CustomerStateType, FetchCustomerResp } from './customer.data';
import { deleteCustomer, updateCustomer, fetchCustomers, getCustomerDetail } from './services';

export type CustomerModelType = {
  namespace: string;
  state: CustomerStateType;
  effects: {
    fetchCustomers: Effect;
    updateCustomer: Effect;
    deleteCustomer: Effect;
    getCustomerDetail: Effect;
  };
  reducers: {
    fetchCustomersSuccess: Reducer<CustomerModelType>;
    getCustomerDetailSuccess: Reducer<CustomerModelType>;
  };
};

const Model: CustomerModelType = {
  namespace: 'customer',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchCustomers({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchCustomerResp = yield call(fetchCustomers, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchCustomersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getCustomerDetail({ payload: { id } }, { call, put }) {
      const response: Customer = yield call(getCustomerDetail, id);
      yield put({
        type: 'getCustomerDetailSuccess',
        payload: { customer: response },
      });
    },
    *updateCustomer({ payload: { id, data } }, { call }) {
      yield call(updateCustomer, id, data);
    },
    *deleteCustomer({ payload }, { call, put }) {
      const response = yield call(deleteCustomer, payload);

      if (response?.success) {
        message.success('The supplier has been deleted.');
        yield put({
          type: 'fetchCustomers',
        });
      }
    },
  },
  reducers: {
    fetchCustomersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getCustomerDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
