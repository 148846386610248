import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { deletePolicy, fetchPolicies, updatePolicy } from './services';
import { FetchPoliciesResp, PolicyStateType } from '@/pages/Policy/policy.data';

export type PolicyModelType = {
  namespace: string;
  state: PolicyStateType;
  effects: {
    fetchPolicies: Effect;
    updatePolicy: Effect;
    deletePolicy: Effect;
  };
  reducers: {
    fetchPoliciesSuccess: Reducer<PolicyStateType>;
  };
};

const Model: PolicyModelType = {
  namespace: 'policy',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchPolicies({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.role.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchPoliciesResp = yield call(fetchPolicies, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchPoliciesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updatePolicy({ payload: { id, data } }, { call }) {
      yield call(updatePolicy, id, data);
    },
    *deletePolicy({ payload }, { call, put }) {
      // @ts-ignore
      // @typescript-eslint/no-shadow
      const response = yield call(deletePolicy, payload);

      if (response?.success) {
        message.success('The Policy has been deleted.').then(() => {});
        yield put({
          type: 'fetchPolicies',
        });
      }
    },
  },
  reducers: {
    fetchPoliciesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
