import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import {
  deleteVisaService,
  fetchVisaServices,
  getVisaServiceDetail,
  updateVisaService,
} from './services';
import {
  FetchVisaServiceResp,
  VisaService,
  VisaServiceStateType,
} from '@/pages/VisaService/visa-service.data';

export type VisaServiceModelType = {
  namespace: string;
  state: VisaServiceStateType;
  effects: {
    fetchVisaServices: Effect;
    updateVisaService: Effect;
    deleteVisaService: Effect;
    getVisaServiceDetail: Effect;
  };
  reducers: {
    fetchVisaServicesSuccess: Reducer<VisaServiceStateType>;
    getVisaServiceDetailSuccess: Reducer<VisaServiceStateType>;
  };
};

const Model: VisaServiceModelType = {
  namespace: 'visaService',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchVisaServices({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchVisaServiceResp = yield call(
        fetchVisaServices,
        _.omitBy(query, _.isNil),
      );

      yield put({
        type: 'fetchVisaServicesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateVisaService({ payload: { id, data } }, { call }) {
      yield call(updateVisaService, id, data);
    },
    *getVisaServiceDetail({ payload: { id, isLive } }, { call, put }) {
      const response: VisaService = yield call(getVisaServiceDetail, id, isLive);
      yield put({
        type: 'getVisaServiceDetailSuccess',
        payload: { visaService: response },
      });
    },
    *deleteVisaService({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteVisaService, payload);

      if (response?.success) {
        message.success('The visa service has been deleted.');
        yield put({
          type: 'fetchVisaServices',
        });
      }
    },
  },
  reducers: {
    fetchVisaServicesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getVisaServiceDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
