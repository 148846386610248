import request from '@/utils/request';
import type { FetchSupplierResp } from '@/pages/Supplier/supplier.data';

export async function createSupplier(params: any) {
  return request.post(`/suppliers`, {
    data: params,
  });
}

export async function fetchSuppliers(params?: any): Promise<FetchSupplierResp> {
  return request.get('/suppliers', {
    params,
  });
}

export async function getSupplierDetail(id: string): Promise<any> {
  return request.get(`/suppliers/${id}`);
}

export async function updateSupplier(id: string, params?: any): Promise<any> {
  return request.patch(`/suppliers/${id}`, {
    data: params,
  });
}

export async function deleteSupplier(id: string): Promise<any> {
  return request.delete(`/suppliers/${id}`);
}
