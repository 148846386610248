import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import {
  deleteTicketService,
  fetchTicketServices,
  getTicketServiceDetail,
  updateTicketService,
} from './services';
import {
  FetchTicketServiceResp,
  TicketService,
  TicketServiceStateType,
} from '@/pages/TicketService/ticket-service.data';

export type TicketServiceModelType = {
  namespace: string;
  state: TicketServiceStateType;
  effects: {
    fetchTicketServices: Effect;
    updateTicketService: Effect;
    deleteTicketService: Effect;
    getTicketServiceDetail: Effect;
  };
  reducers: {
    fetchTicketServicesSuccess: Reducer<TicketServiceStateType>;
    getTicketServiceDetailSuccess: Reducer<TicketServiceStateType>;
  };
};

const Model: TicketServiceModelType = {
  namespace: 'ticketService',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchTicketServices({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchTicketServiceResp = yield call(
        fetchTicketServices,
        _.omitBy(query, _.isNil),
      );

      yield put({
        type: 'fetchTicketServicesSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateTicketService({ payload: { id, data } }, { call }) {
      yield call(updateTicketService, id, data);
    },
    *getTicketServiceDetail({ payload: { id, isLive } }, { call, put }) {
      const response: TicketService = yield call(getTicketServiceDetail, id, isLive);
      yield put({
        type: 'getTicketServiceDetailSuccess',
        payload: { ticketService: response },
      });
    },
    *deleteTicketService({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteTicketService, payload);

      if (response?.success) {
        message.success('The Ticket service has been deleted.');
        yield put({
          type: 'fetchTicketServices',
        });
      }
    },
  },
  reducers: {
    fetchTicketServicesSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getTicketServiceDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
