import request from '@/utils/request';
import type { FetchStaffResp } from './staff.data';
import type { UserDataType } from '@/pages/User/user.data';

const PARTNER_API = 'partners';

export async function fetchStaffs(params?: any): Promise<FetchStaffResp> {
  return request.get(`/${PARTNER_API}/staffs`, {
    params,
  });
}

export async function createStaff(params: any) {
  return request.post(`/${PARTNER_API}/create-staff`, {
    data: params,
  });
}

export async function getStaffDetail(id: string): Promise<UserDataType> {
  return request.get(`/${PARTNER_API}/staff/${id}`);
}

export async function updateStaff(id: string, params: any) {
  return request.patch(`/${PARTNER_API}/staff/${id}`, {
    data: params,
  });
}
