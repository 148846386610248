import request, { openRequest } from '@/utils/request';

export type LoginParams = {
  token: string;
};

export type AuthParams = {
  token: string;
};

export type LoginResp = {
  accessToken: string;
  user: any;
};

export interface NotificationDataType {
  message: string;
  createdAt: string;
}

export interface FetchNotificationResp {
  list: NotificationDataType[];
  total: number;
  limit: number;
  page: number;
  newCount?: number;
}

export async function login(params: LoginParams): Promise<LoginResp> {
  return openRequest.post('/auth/login', {
    data: params,
  });
}

export async function google(params: AuthParams): Promise<LoginResp> {
  return openRequest.post('/auth/google', {
    data: params,
  });
}

export async function facebook(params: AuthParams): Promise<LoginResp> {
  return openRequest.post('/auth/facebook', {
    data: params,
  });
}

export async function getProfile() {
  return request('/cms/profile');
}

export async function getNotifications(params?: any): Promise<FetchNotificationResp> {
  return request('/fcm-notifications', { params });
}
