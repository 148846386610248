import request, { exportRequest } from '@/utils/request';
import type { FetchHotelOrderResp, HotelOrder } from '@/pages/HotelOrder/hotel-order.data';

export async function createHotelOrder(params: any): Promise<HotelOrder> {
  return request.post(`/cms/hotel-orders`, {
    data: params,
  });
}

export async function fetchHotelOrders(params?: any): Promise<FetchHotelOrderResp> {
  return request.get('/cms/hotel-orders', {
    params,
  });
}

export async function updateHotelOrder(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/hotel-orders/${id}`, {
    data: params,
  });
}

export async function getDetailHotelOrder(id: string): Promise<HotelOrder> {
  return request.get(`/cms/hotel-orders/${id}`);
}

export async function deleteHotelOrder(id: string): Promise<any> {
  return request.delete(`/cms/hotel-orders/${id}`);
}

export async function exportPdf(id: string) {
  return exportRequest.get(`/hotel-orders/export/${id}`);
}
