import request, { exportRequest } from '@/utils/request';

import type { PartnerTourDetail } from './tour.data';
import type { FetchPartnerTourResp } from './tour.data';
import type { FetchPlaceResp } from '@/pages/Place/place.data';

export async function createTour(params: any) {
  return request.post(`/partner-tours`, {
    data: params,
  });
}

export async function fetchPartnerTours(params?: any): Promise<FetchPartnerTourResp> {
  return request.get('/partner-tours', {
    params,
  });
}

export async function getPartnerTourDetail(id: string): Promise<PartnerTourDetail> {
  return request.get(`/partner-tours/${id}`);
}

export async function updatePartnerTour(id: string, params: any) {
  return request.patch(`/partner-tours/${id}`, {
    data: params,
  });
}

export async function copyPartnerTour(id: string) {
  return request.post(`/partner-tours/copy/${id}`);
}

export async function deletePartnerTour(id: string) {
  return request.delete(`/partner-tours/${id}`);
}

export async function fetchPlaces(params?: any): Promise<FetchPlaceResp> {
  return request.get('/places', {
    params,
  });
}

export async function exportPdf(id: string) {
  return exportRequest.get(`/tours/export/${id}`);
}
