import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import { deleteVisa, fetchVisas, getVisaDetail, updateVisa } from './services';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';
import type { FetchVisaResp, VisaStateType } from '@/pages/Visa/visa.data';
import type { Order } from '@/pages/Order/order.data';

export type VisaModelType = {
  namespace: string;
  state: VisaStateType;
  effects: {
    fetchVisas: Effect;
    updateVisa: Effect;
    deleteVisa: Effect;
    getVisaDetail: Effect;
  };
  reducers: {
    fetchVisasSuccess: Reducer<VisaStateType>;
    getVisaDetailSuccess: Reducer<VisaStateType>;
  };
};

const Model: VisaModelType = {
  namespace: 'visa',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchVisas({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchVisaResp = yield call(fetchVisas, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchVisasSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *updateVisa({ payload: { id, data } }, { call }) {
      yield call(updateVisa, id, data);
    },
    *getVisaDetail({ payload: { id } }, { call, put }) {
      const response: Order = yield call(getVisaDetail, id);
      yield put({
        type: 'getVisaDetailSuccess',
        payload: { visa: response },
      });
    },
    *deleteVisa({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteVisa, payload);

      if (response?.success) {
        message.success('The visa has been deleted.');
        yield put({
          type: 'fetchVisas',
        });
      }
    },
  },
  reducers: {
    fetchVisasSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getVisaDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
