import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import {
  copyPartnerTour,
  deletePartnerTour,
  fetchPartnerTours,
  updatePartnerTour,
  getPartnerTourDetail,
} from './services';
import type { FetchPartnerTourResp, PartnerTourStateType } from './tour.data';
import type { ConnectState } from '@/models/connect';
import { message } from 'antd';
import type { PartnerTourDetail } from './tour.data';

export type PartnerTourModelType = {
  namespace: string;
  state: PartnerTourStateType;
  effects: {
    getDetail: Effect;
    copyPartnerTour: Effect;
    fetchPartnerTours: Effect;
    updatePartnerTour: Effect;
    deletePartnerTour: Effect;
  };
  reducers: {
    getDetailSuccess: Reducer<PartnerTourStateType>;
    fetchPartnerToursSuccess: Reducer<PartnerTourStateType>;
  };
};

const Model: PartnerTourModelType = {
  namespace: 'tour',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchPartnerTours({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.user.query);

      const query = _.pickBy({ ...currentQuery, ...updatedParams }, (value) => value !== '');

      const response: FetchPartnerTourResp = yield call(
        fetchPartnerTours,
        _.omitBy(query, _.isNil),
      );

      yield put({
        type: 'fetchPartnerToursSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetail({ payload: { partnerTourId } }, { call, put }) {
      const response: PartnerTourDetail = yield call(getPartnerTourDetail, partnerTourId);
      yield put({
        type: 'getDetailSuccess',
        payload: { tour: response.tour, partnerPackage: response.partnerPackage },
      });
    },
    *updatePartnerTour({ payload: { partnerTourId, data } }, { call }) {
      yield call(updatePartnerTour, partnerTourId, data);
    },
    *copyPartnerTour({ payload: { partnerTourId } }, { call }) {
      yield call(copyPartnerTour, partnerTourId);
    },
    *deletePartnerTour({ payload }, { call, put }) {
      // @ts-ignore
      const response = yield call(deletePartnerTour, payload);

      if (response?.success) {
        message.success('The partner tour has been deleted.');
        yield put({
          type: 'fetchTours',
        });
      }
    },
  },
  reducers: {
    fetchPartnerToursSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
