import type { Reducer, Effect } from 'umi';
import _ from 'lodash';

import {
  fetch,
  countUnread,
  getBookingDetail,
  addQuotation,
  fetchQuotations,
  updateQuotation,
  addReceipt,
  sendInvoice,
  cancelBooking,
  verifyBooking,
  completeBooking,
  cancelQuotation,
  getReceiptsByBooking,
  cancelReceipt,
  approveReceipt,
} from './services';
import type {
  FetchResp,
  BookingState,
  Booking,
  Quotation,
  Receipt,
  FetchQuotationRespType,
} from './booking.data';
import type { ConnectState } from '@/models/connect';
import { notification } from 'antd';

export type BookingModelType = {
  namespace: string;
  state: BookingState;
  effects: {
    fetch: Effect;
    getDetail: Effect;
    countUnread: Effect;
    addQuotation: Effect;
    fetchQuotations: Effect;
    updateQuotation: Effect;
    addReceipt: Effect;
    sendInvoice: Effect;
    verifyBooking: Effect;
    cancelBooking: Effect;
    completeBooking: Effect;
    cancelQuotation: Effect;
    getReceipts: Effect;
    cancelReceipt: Effect;
    approveReceipt: Effect;
  };
  reducers: {
    fetchSuccess: Reducer<BookingState>;
    fetchQuotationsSuccess: Reducer<BookingState>;
    getDetailSuccess: Reducer<BookingState>;
    updateStatusSuccess: Reducer<BookingState>;
    addQuotationSuccess: Reducer<BookingState>;
    getReceiptsSuccess: Reducer<BookingState>;
    updateQuotationSuccess: Reducer<BookingState>;
  };
};

const Model: BookingModelType = {
  namespace: 'booking',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
    unread: 0,
    booking: null,
    quotations: [],
    receipts: [],
  },

  effects: {
    *fetch({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.booking.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchResp = yield call(fetch, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetail({ payload: { bookingId } }, { call, put }) {
      const response: Booking = yield call(getBookingDetail, bookingId);
      yield put({
        type: 'getDetailSuccess',
        payload: { booking: response },
      });
    },
    *getReceipts({ payload: { bookingId } }, { call, put }) {
      const response: Receipt[] = yield call(getReceiptsByBooking, bookingId);
      yield put({
        type: 'getReceiptsSuccess',
        payload: { receipts: response },
      });
    },
    *sendInvoice({ payload: { bookingId } }, { call, put }) {
      const response: Booking = yield call(sendInvoice, bookingId);
      yield put({
        type: 'fetchQuotations',
        payload: { bookingId },
      });
      yield put({
        type: 'updateStatusSuccess',
        payload: response,
      });
    },
    *cancelBooking({ payload: { bookingId } }, { call, put }) {
      const response: Booking = yield call(cancelBooking, bookingId);
      yield put({
        type: 'updateStatusSuccess',
        payload: response,
      });
    },
    *verifyBooking({ payload: { bookingId } }, { call, put }) {
      const response: Booking = yield call(verifyBooking, bookingId);
      yield put({
        type: 'updateStatusSuccess',
        payload: response,
      });
    },
    *completeBooking({ payload: { bookingId } }, { call, put }) {
      const response: Booking = yield call(completeBooking, bookingId);
      yield put({
        type: 'updateStatusSuccess',
        payload: response,
      });
    },
    *fetchQuotations({ payload: { bookingId } }, { call, put }) {
      const response: FetchQuotationRespType<Quotation>[] = yield call(fetchQuotations, {
        bookingId,
      });
      const list = _.reduce(
        response,
        (quotations, item) => {
          const flattenList = item.list.map((quotation) => ({
            ...quotation,
            partner: item.partner,
          }));
          // @ts-ignore
          return quotations.concat(flattenList);
        },
        [],
      );
      yield put({
        type: 'fetchQuotationsSuccess',
        payload: { quotations: list },
      });
    },
    *cancelQuotation({ payload: { id } }, { call, put }) {
      const response: Quotation = yield call(cancelQuotation, id);
      yield put({
        type: 'updateQuotationSuccess',
        payload: response,
      });
    },
    *addQuotation({ payload }, { call, put }) {
      console.log(123);
      try {
        const response: Booking = yield call(addQuotation, payload);
        notification.success({
          description: 'Gửi báo giá thành công',
          message: '',
        });
        yield put({
          type: 'addQuotationSuccess',
          payload: { quotation: response },
        });
      } catch (error: any) {
        let errorMessage = error?.data?.message;
        errorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
        notification.error({
          description: errorMessage || 'Gửi báo giá thất bại.',
          message: '',
        });
        throw error;
      }
    },

    *addReceipt({ payload }, { call, put }) {
      try {
        const response: Receipt = yield call(addReceipt, payload);
        notification.success({
          description: 'Thêm phiếu thu thành công',
          message: '',
        });
        yield put({
          type: 'addReceiptSuccess',
          payload: response,
        });
        yield put({
          type: 'getReceipts',
          payload: { bookingId: response.booking },
        });
      } catch (error: any) {
        let errorMessage = error?.data?.message;
        errorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
        notification.error({
          description: errorMessage || 'Thêm phiếu thu thất bại.',
          message: '',
        });
        throw error;
      }
    },

    *cancelReceipt({ payload: { receiptId } }, { call, put }) {
      const response: Receipt = yield call(cancelReceipt, receiptId);
      yield put({
        type: 'getReceipts',
        payload: { bookingId: response.booking },
      });
    },

    *approveReceipt({ payload: { receiptId } }, { call, put }) {
      const response: Receipt = yield call(approveReceipt, receiptId);
      yield put({
        type: 'getReceipts',
        payload: { bookingId: response.booking },
      });
    },

    *updateQuotation({ payload }, { call, put }) {
      try {
        const response: Quotation = yield call(updateQuotation, payload.id, payload.updateData);
        notification.success({
          description: 'Gửi báo giá thành công',
          message: '',
        });
        yield put({
          type: 'updateQuotationSuccess',
          payload: response,
        });
      } catch (error: any) {
        let errorMessage = error?.data?.message;
        errorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage;
        notification.error({
          description: errorMessage || 'Gửi báo giá thất bại.',
          message: '',
        });
        throw error;
      }
    },

    // eslint-disable-next-line no-empty-pattern
    *countUnread({}, { call, put }) {
      const response = yield call(countUnread);

      yield put({
        type: 'fetchSuccess',
        payload: {
          unread: response,
        },
      });
    },
  },
  reducers: {
    fetchSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getReceiptsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    fetchQuotationsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    updateStatusSuccess(state, { payload }) {
      const list = state?.list;
      if (list) {
        const foundBookingIndex = state?.list?.findIndex((b) => b._id === payload._id);
        // @ts-ignore
        list[foundBookingIndex] = payload;
      }
      return {
        ...state,
        list: [...(list || [])],
        booking: payload,
      } as BookingState;
    },
    addQuotationSuccess(state, { payload }) {
      const quotations = [...(state?.quotations || []), payload.quotation];
      return {
        ...state,
        quotations,
      } as BookingState;
    },
    updateQuotationSuccess(state, { payload }) {
      const quotations = state?.quotations;
      if (quotations) {
        const foundQuotationIndex = state?.quotations?.findIndex((q) => q._id === payload._id);
        // @ts-ignore
        quotations[foundQuotationIndex] = payload;
      }
      return {
        ...state,
        quotations: [...(quotations || [])],
      } as BookingState;
    },
  },
};

export default Model;
