import request from '@/utils/request';

import type { FetchPlaceResp, ISearchPlaceParams, PlaceDataType } from './place.data';

export async function createPlace(params: any) {
  return request.post(`/cms/places`, {
    data: params,
  });
}

export async function fetchPlaces(params?: any): Promise<FetchPlaceResp> {
  return request.get('/cms/places', {
    params,
  });
}

export async function searchPlaces(params?: ISearchPlaceParams): Promise<PlaceDataType[]> {
  return request.get('/places/suggestion', {
    params,
  });
}

export async function getPlaceDetail(id: string): Promise<PlaceDataType> {
  return request.get(`/cms/places/${id}`);
}

export async function updatePlace(id: string, params: any) {
  return request.patch(`/cms/places/${id}`, {
    data: params,
  });
}

export async function deletePlace(id: string) {
  return request.delete(`/cms/places/${id}`);
}

export async function mergePlace(params: any) {
  return request.post(`/cms/places/merge`, {
    data: params,
  });
}
