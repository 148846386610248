import _ from 'lodash';
import { fetchStaffs } from './services';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { RoleStateType } from '@/pages/Role/role.data';
import { FetchStaffResp, StaffStateType } from '@/pages/Staff/staff.data';

export type StaffModelType = {
  namespace: string;
  state: RoleStateType;
  effects: {
    fetchStaffs: Effect;
  };
  reducers: {
    fetchStaffsSuccess: Reducer<StaffStateType>;
  };
};

const Model: StaffModelType = {
  namespace: 'staff',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchStaffs({ payload: params }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.staff.query);

      const query = { ...currentQuery, ...params };

      const response: FetchStaffResp = yield call(fetchStaffs, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchStaffsSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
  },
  reducers: {
    fetchStaffsSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
