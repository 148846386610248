import request from '@/utils/request';
import { TicketService } from '@/pages/TicketService/ticket-service.data';
import type { FetchCostCategoryResp } from '@/pages/CostCategory/cost-category.data';

export async function createTicketService(params: any) {
  return request.post(`/cms/ticket-services`, {
    data: params,
  });
}

export async function fetchTicketServices(params?: any): Promise<FetchCostCategoryResp> {
  return request.get('/cms/ticket-services', {
    params,
  });
}

export async function getTicketServiceDetail(id: string, isLive?: boolean): Promise<TicketService> {
  return request.get(`${isLive ? '' : '/cms'}/ticket-services/${id}`);
}

export async function updateTicketService(id: string, params?: any): Promise<any> {
  return request.patch(`/cms/ticket-services/${id}`, {
    data: params,
  });
}

export async function deleteTicketService(id: string): Promise<any> {
  return request.delete(`/cms/ticket-services/${id}`);
}
