import { UserRole } from '../enum';
import { Partner } from '@/pages/Partner/partner.data';
import { RoleDataType } from '@/pages/Role/role.data';

export class CurrentUserDto {
  id: string;
  name: string;
  email: string;
  avatar: string;
  partner?: string;
  roles: UserRole[];
  myPartner?: Partner;
  myRole?: RoleDataType;

  constructor(init?: Partial<CurrentUserDto>) {
    Object.assign(this, init);
  }

  static fromUser(user: User) {
    return new CurrentUserDto({
      id: user.id,
      name: user.name,
      email: user.email,
      myRole: user.myRole,
      avatar: user.avatar,
      partner: user.partner,
      roles: user.roles || [],
      myPartner: user.myPartner,
    });
  }

  get isUser(): boolean {
    return this.roles.includes(UserRole.USER);
  }

  get isOnlyUser(): boolean {
    return this.roles.includes(UserRole.USER) && this.roles.length === 1;
  }

  get isAdmin(): boolean {
    return this.roles.includes(UserRole.ADMIN);
  }

  get isSuperAdmin(): boolean {
    return this.roles.includes(UserRole.SUPER_ADMIN);
  }

  get isMod(): boolean {
    return this.roles.includes(UserRole.MOD);
  }

  get isLodgingService(): boolean {
    return this.roles.includes(UserRole.LODGING_SERVICE);
  }

  get isTravelerService(): boolean {
    return this.roles.includes(UserRole.TRAVELER_SERVICE);
  }

  get isNotAgentAndCollaborator(): boolean {
    return (
      !this.roles.includes(UserRole.TRAVELER_COLLABORATOR) &&
      !this.roles.includes(UserRole.TRAVELER_AGENT)
    );
  }

  get isModAndAbove(): boolean {
    return this.isAdminAndAbove || this.isMod;
  }

  get isAdminAndAbove(): boolean {
    return this.isAdmin || this.isSuperAdmin;
  }

  checkPermission(permission: string): boolean {
    if (this.isTravelerService || this.isModAndAbove) return true;

    if (!this?.myRole) return false;

    return this.myRole?.permissions?.includes(permission);
  }

  get isTourIndex(): boolean {
    if (this.isTravelerService || this.isModAndAbove) return false;

    return this.checkPermission('partner_tour_index');
  }

  get isTourCreate(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_create');
  }

  get isTourUpdate(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_update');
  }

  get isTourCopy(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_copy');
  }

  get isTourDelete(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes('partner_tour_delete');
  }

  get isTourDetail(): boolean {
    if (this.isTravelerService) return true;

    return this.myRole?.permissions.includes(`partner_tour_detail`);
  }

  // order
  get isOrderIndex(): boolean {
    return this.checkPermission('order_index');
  }

  get isOrderCreate(): boolean {
    return this.checkPermission('order_create');
  }

  get isOrderUpdate(): boolean {
    return this.checkPermission('order_update');
  }

  get isOrderDelete(): boolean {
    return this.checkPermission('order_delete');
  }

  get isOrderDetail(): boolean {
    return this.checkPermission('order_detail');
  }

  get isOrderReceipt(): boolean {
    return this.checkPermission('order_receipt');
  }

  get isOrderPrint(): boolean {
    return this.checkPermission('order_print');
  }

  // visa
  get isVisaIndex(): boolean {
    return this.checkPermission('visa_index');
  }

  get isVisaCreate(): boolean {
    return this.checkPermission('visa_create');
  }

  get isVisaUpdate(): boolean {
    return this.checkPermission('visa_update');
  }

  get isVisaDelete(): boolean {
    return this.checkPermission('visa_delete');
  }

  get isVisaDetail(): boolean {
    return this.checkPermission('visa_detail');
  }

  get isVisaReceipt(): boolean {
    return this.checkPermission('visa_receipt');
  }

  get isVisaPrint(): boolean {
    return this.checkPermission('visa_print');
  }

  get partnerLogo(): boolean {
    return this?.myPartner?.logo || '';
  }

  // role
  get isRoleIndex(): boolean {
    return this.checkPermission('role_index');
  }

  get isRoleCreate(): boolean {
    return this.checkPermission('role_create');
  }

  get isRoleUpdate(): boolean {
    return this.checkPermission('role_update');
  }

  get isRoleDelete(): boolean {
    return this.checkPermission('role_delete');
  }

  // cost
  get isCostIndex(): boolean {
    return this.checkPermission('cost_index');
  }

  get isCostCreate(): boolean {
    return this.checkPermission('cost_create');
  }

  get isCostUpdate(): boolean {
    return this.checkPermission('cost_update');
  }

  get isCostDelete(): boolean {
    return this.checkPermission('cost_delete');
  }

  get isCostPayment(): boolean {
    return this.checkPermission('cost_payment');
  }

  get isCostDetail(): boolean {
    return this.checkPermission('cost_detail');
  }

  // customer
  get isCustomerIndex(): boolean {
    return this.checkPermission('customer_index');
  }

  get isCustomerCreate(): boolean {
    return this.checkPermission('customer_create');
  }

  get isCustomerUpdate(): boolean {
    return this.checkPermission('customer_update');
  }

  get isCustomerDelete(): boolean {
    return this.checkPermission('customer_delete');
  }

  // cost category
  get isCostCategory(): boolean {
    return this.checkPermission('cost_category');
  }

  // supplier
  get isSupplierIndex(): boolean {
    return this.checkPermission('supplier_index');
  }

  get isSupplierCreate(): boolean {
    return this.checkPermission('supplier_create');
  }

  get isSupplierUpdate(): boolean {
    return this.checkPermission('supplier_update');
  }

  get isSupplierDelete(): boolean {
    return this.checkPermission('supplier_delete');
  }

  // staff
  get isStaffIndex(): boolean {
    return this.checkPermission('staff_index');
  }

  get isStaffCreate(): boolean {
    return this.checkPermission('staff_create');
  }

  get isStaffUpdate(): boolean {
    return this.checkPermission('staff_update');
  }

  get isStaffDelete(): boolean {
    return this.checkPermission('staff_delete');
  }

  // agent
  get isAgentIndex(): boolean {
    return this.checkPermission('agent_index');
  }

  get isAgentCreate(): boolean {
    return this.checkPermission('agent_create');
  }

  get isAgentUpdate(): boolean {
    return this.checkPermission('agent_update');
  }

  get isAgentDelete(): boolean {
    return this.checkPermission('agent_delete');
  }

  // activity_history
  get isActivityIndex(): boolean {
    return this.checkPermission('activity_history_index');
  }

  // ctv
  get isCtvIndex(): boolean {
    return this.checkPermission('collaborator_index');
  }

  get isCtvCreate(): boolean {
    return this.checkPermission('collaborator_create');
  }

  get isCtvUpdate(): boolean {
    return this.checkPermission('collaborator_update');
  }

  get isCtvDelete(): boolean {
    return this.checkPermission('collaborator_delete');
  }
}
