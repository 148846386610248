import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { FetchOrderResp, Order, OrderStateType } from './order.data';
import { deleteOrder, fetchOrders, getDetailOrder, updateOrder } from './services';

export type OrderModelType = {
  namespace: string;
  state: OrderStateType;
  effects: {
    fetchOrders: Effect;
    updateOrder: Effect;
    deleteOrder: Effect;
    getDetailOrder: Effect;
  };
  reducers: {
    fetchOrdersSuccess: Reducer<OrderStateType>;
    getDetailOrderSuccess: Reducer<OrderStateType>;
  };
};

const Model: OrderModelType = {
  namespace: 'order',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchOrders({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.place.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchOrderResp = yield call(fetchOrders, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchOrdersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          tourSells: response.tourSells,
          query,
        },
      });
    },
    *getDetailOrder({ payload: { id } }, { call, put }) {
      const response: Order = yield call(getDetailOrder, id);
      yield put({
        type: 'getDetailOrderSuccess',
        payload: { order: response },
      });
    },
    *updateOrder({ payload: { orderId, data } }, { call }) {
      yield call(updateOrder, orderId, data);
    },
    *deleteOrder({ payload: { id } }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteOrder, id);

      if (response?.success) {
        message.success('The order has been deleted.');
        yield put({
          type: 'fetchOrders',
        });
      }
    },
  },
  reducers: {
    fetchOrdersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailOrderSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
