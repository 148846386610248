import request from '@/utils/request';

import type { FetchMenusResp } from './menu.data';

const MENUS_API = 'menus';

export async function fetchMenus(params?: any): Promise<FetchMenusResp> {
  return request.get(`/${MENUS_API}`, {
    params,
  });
}

export async function addMenu(data: any): Promise<any> {
  return request.post(`/${MENUS_API}`, {
    data,
  });
}

export async function updateMenu(id: string, data: any): Promise<any> {
  return request.patch(`/${MENUS_API}/${id}`, {
    data,
  });
}

export async function deleteMenu(id: string): Promise<any> {
  return request.delete(`/${MENUS_API}/${id}`);
}
