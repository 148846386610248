import request from '@/utils/request';
import type { Quotation } from '../Booking/booking.data';

import type { Partner, FetchResp, FetchPoliciesResp, PaginationResp } from './partner.data';
import type { PartnerPackage } from '@/pages/Tour/tour.data';
import type { MyPartnerResp } from './partner.data';

const PARTNER_API = 'partners';
const PARTNER_POLICIES_API = 'partner-policies';

export async function fetch(params?: any): Promise<FetchResp> {
  return request.get(`/${PARTNER_API}`, {
    params,
  });
}

export async function fetchQuotations(params?: any): Promise<PaginationResp<Quotation>> {
  return request.get(`/${PARTNER_API}/by-partner`, {
    params,
  });
}

export async function addPartner(data: any): Promise<any> {
  return request.post(`/${PARTNER_API}`, {
    data,
  });
}

export async function setApprovePolicySellApi(): Promise<any> {
  return request.post(`/${PARTNER_API}/approve-policy-sell`);
}

export async function updatePartner(id: string, data: any): Promise<any> {
  return request.patch(`/${PARTNER_API}/${id}`, {
    data,
  });
}

export async function changePasswordPartner(id: string, data: any): Promise<any> {
  return request.patch(`/${PARTNER_API}/change-password/${id}`, {
    data,
  });
}

export async function getPartnerDetail(id: string): Promise<Partner> {
  return request.get(`/${PARTNER_API}/${id}`);
}

export async function getMyPartnerDetail(): Promise<MyPartnerResp> {
  return request.get(`/${PARTNER_API}/my-partner`);
}

export async function fetchPartnerPackages(): Promise<PartnerPackage[]> {
  return request.get(`/${PARTNER_API}/packages`);
}

export async function deletePartner(id: string): Promise<Partner> {
  return request.delete(`/${PARTNER_API}/${id}`);
}

export async function updatePartnerStatus(id: string, params: any) {
  return request.patch(`/${PARTNER_API}/${id}`, {
    data: params,
  });
}

export async function countUnread(params?: any): Promise<FetchResp> {
  return request.get(`/${PARTNER_API}/countUnread`, {
    params,
  });
}

export async function fetchPolicies(params?: any): Promise<FetchPoliciesResp> {
  return request.get(`/${PARTNER_POLICIES_API}`, {
    params,
  });
}

export async function addPolicy(data: any): Promise<any> {
  return request.post(`/${PARTNER_POLICIES_API}`, {
    data,
  });
}

export async function updatePolicy(id: string, data: any): Promise<any> {
  return request.patch(`/${PARTNER_POLICIES_API}/${id}`, {
    data,
  });
}

export async function deletePolicy(id: string): Promise<any> {
  return request.delete(`/${PARTNER_POLICIES_API}/${id}`);
}
