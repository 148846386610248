import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import { deleteSupplier, fetchSuppliers, updateSupplier, getSupplierDetail } from './services';
import type { SupplierStateType, FetchSupplierResp } from './supplier.data';
import type { Customer } from '@/pages/Customer/customer.data';

export type SupplierModelType = {
  namespace: string;
  state: SupplierStateType;
  effects: {
    getDetail: Effect;
    fetchSuppliers: Effect;
    updateSupplier: Effect;
    deleteSupplier: Effect;
  };
  reducers: {
    getDetailSuccess: Reducer<SupplierStateType>;
    fetchSuppliersSuccess: Reducer<SupplierStateType>;
  };
};

const Model: SupplierModelType = {
  namespace: 'supplier',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchSuppliers({ payload: updatedParams }, { call, put, select }) {
      const currentQuery = yield select((state: ConnectState) => state.cost.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchSupplierResp = yield call(fetchSuppliers, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchSuppliersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetail({ payload: { id } }, { call, put }) {
      const response: Customer = yield call(getSupplierDetail, id);
      yield put({
        type: 'getDetailSuccess',
        payload: { supplier: response },
      });
    },
    *updateSupplier({ payload: { id, data } }, { call }) {
      yield call(updateSupplier, id, data);
    },
    *deleteSupplier({ payload }, { call, put }) {
      const response = yield call(deleteSupplier, payload);

      if (response?.success) {
        message.success('The supplier has been deleted.');
        yield put({
          type: 'fetchSuppliers',
        });
      }
    },
  },
  reducers: {
    fetchSuppliersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
