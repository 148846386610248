import request from '@/utils/request';
import type { Customer, FetchCustomerResp } from '@/pages/Customer/customer.data';

export async function createCustomer(params: any) {
  return request.post(`/customers`, {
    data: params,
  });
}

export async function fetchCustomers(params?: any): Promise<FetchCustomerResp> {
  return request.get('/customers', {
    params,
  });
}

export async function getCustomerDetail(id: string): Promise<Customer> {
  return request.get(`/customers/${id}`);
}

export async function updateCustomer(id: string, params?: any): Promise<any> {
  return request.patch(`/customers/${id}`, {
    data: params,
  });
}

export async function deleteCustomer(id: string): Promise<any> {
  return request.delete(`/customers/${id}`);
}
