import _ from 'lodash';
import { message } from 'antd';
import type { Reducer, Effect } from 'umi';
import type { ConnectState } from '@/models/connect';
import type { FetchHotelOrderResp, HotelOrder, HotelOrderStateType } from './hotel-order.data';
import {
  deleteHotelOrder,
  fetchHotelOrders,
  getDetailHotelOrder,
  updateHotelOrder,
} from './services';

export type HotelOrderModelType = {
  namespace: string;
  state: HotelOrderStateType;
  effects: {
    fetchHotelOrders: Effect;
    updateHotelOrder: Effect;
    deleteHotelOrder: Effect;
    getDetailHotelOrder: Effect;
  };
  reducers: {
    fetchHotelOrdersSuccess: Reducer<HotelOrderModelType>;
    getDetailHotelOrderSuccess: Reducer<HotelOrderStateType>;
  };
};

const Model: HotelOrderModelType = {
  namespace: 'hotelOrder',

  state: {
    list: [],
    query: {
      page: 1,
      limit: 10,
    },
    total: 0,
  },

  effects: {
    *fetchHotelOrders({ payload: updatedParams }, { call, put, select }) {
      // @ts-ignore
      const currentQuery = yield select((state: ConnectState) => state.place.query);

      const query = { ...currentQuery, ...updatedParams };

      const response: FetchHotelOrderResp = yield call(fetchHotelOrders, _.omitBy(query, _.isNil));

      yield put({
        type: 'fetchHotelOrdersSuccess',
        payload: {
          list: response.list,
          total: response.total,
          query,
        },
      });
    },
    *getDetailHotelOrder({ payload: { id } }, { call, put }) {
      const response: HotelOrder = yield call(getDetailHotelOrder, id);
      yield put({
        type: 'getDetailHotelOrderSuccess',
        payload: { hotelOrder: response },
      });
    },
    *updateHotelOrder({ payload: { orderId, data } }, { call }) {
      yield call(updateHotelOrder, orderId, data);
    },
    *deleteHotelOrder({ payload: { id } }, { call, put }) {
      // @ts-ignore
      const response = yield call(deleteHotelOrder, id);

      if (response?.success) {
        message.success('The hotel order has been deleted.');
        yield put({
          type: 'fetchHotelOrders',
        });
      }
    },
  },
  reducers: {
    fetchHotelOrdersSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    getDetailHotelOrderSuccess(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

export default Model;
