import request from '@/utils/request';

import type { Receipt, PaginationResp } from './receipt.data';

const RECEIPT_API = 'receipts';

export async function fetch(params?: any): Promise<PaginationResp<Receipt>> {
  return request.get(`/${RECEIPT_API}`, {
    params,
  });
}

export async function addReceipt(data: any): Promise<any> {
  return request.post(`/${RECEIPT_API}`, {
    data,
  });
}

export async function updateReceipt(id: string, data: any): Promise<any> {
  return request.patch(`/${RECEIPT_API}/${id}`, {
    data,
  });
}

export async function approveReceipt(id: string, data: any): Promise<any> {
  return request.get(`/${RECEIPT_API}/${id}/approve`, {
    data,
  });
}
export async function cancelReceipt(id: string, data: any): Promise<any> {
  return request.get(`/${RECEIPT_API}/${id}/cancel`, {
    data,
  });
}

export async function getReceiptDetail(id: string): Promise<Receipt> {
  return request.get(`/${RECEIPT_API}/${id}`);
}

export async function updateReceiptStatus(id: string, params: any) {
  return request.patch(`/${RECEIPT_API}/${id}`, {
    data: params,
  });
}
