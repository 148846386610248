import request from '@/utils/request';

import type { FetchPoliciesResp } from './policy.data';

const PARTNER_POLICIES_API = 'partner-policies';

export async function fetchPolicies(params?: any): Promise<FetchPoliciesResp> {
  return request.get(`/${PARTNER_POLICIES_API}`, {
    params,
  });
}

export async function addPolicy(data: any): Promise<any> {
  return request.post(`/${PARTNER_POLICIES_API}`, {
    data,
  });
}

export async function updatePolicy(id: string, data: any): Promise<any> {
  return request.patch(`/${PARTNER_POLICIES_API}/${id}`, {
    data,
  });
}

export async function deletePolicy(id: string): Promise<any> {
  return request.delete(`/${PARTNER_POLICIES_API}/${id}`);
}
